import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

const router = new Router({
  // Code splitting in routes breaks this.$route in created() hook?
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/results",
      name: "results",
      component: () => import("./views/Results.vue"),
    },
  ],
})

export default router
