const formatDollars = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}).format

function convertString(string) {
  if (string == "true") {
    return true
  } else if (string == "false") {
    return false
  } else if (/^\d+$/.test(string)) {
    return parseInt(string)
  } else {
    return string
  }
}

export { formatDollars, convertString }
