import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import axios from "axios"
import api from "./utils/api"
import * as Sentry from "@sentry/vue"
import store from "./store"
import Doggo from "@uarizona-fnsv/vue-doggo"
import VueGtag from "vue-gtag"

Vue.config.productionTip = false

// Add global methods for making requests
Vue.prototype.$http = axios
Vue.prototype.$api = api // For internal API, comes with CSRF token.

Vue.use(VueGtag, {
  config: { id: "G-PS9C6PR9MB" },
})

Vue.use(Doggo, {
  amplify: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    domain: process.env.VUE_APP_COGNITO_DOMAIN,
    redirectSignIn: process.env.VUE_APP_COGNITO_SIGN_IN_URL,
  },
  disable: process.env.VUE_APP_DISABLE_AUTH,
})

// Sentry configuration
if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // trackComponents: ["Header", "Navigation", "Footer"],
    // hooks: ["create", "mount"],

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/tuitioncalculator-dev\.fso\.arizona\.edu\/api/,
      /^https:\/\/tuitioncalculator\.fso\.arizona\.edu\/api/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

Vue.prototype.$adminPreview = window.location.pathname === "/admin/preview/"

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
