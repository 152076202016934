<template>
  <div class="toast-container">
    <div
      v-for="(toast, i) in toasts"
      :key="i"
      class="toast show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header text-white bg-danger">
        <strong class="mr-auto">{{ toast.header }}</strong>
        <button
          type="button"
          class="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
          @click="() => $store.commit('removeToast', i)"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="toast-body">
        {{ toast.body }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["toasts"]),
  },
}
</script>

<style lang="postcss" scoped>
.toast {
  transform: translateX(-50%);
  position: relative;
  left: 50%;
  width: 300px;
}

.toast-container {
  position: fixed;
  bottom: 10rem;
  width: 100%;
}
</style>
